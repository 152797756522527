import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-in\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.js\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-mr\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\Gpay.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\Paytm_Logo.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\UpiLogo.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\src\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\src\\components\\Header\\index.js");
