// please update required information
const siteMetadata = {
    title: 'Safe & Easy Sign-Up for Cricket Betting ID | Online Exchange ID',
    author: 'Online Exchange ID',
    headerTitle: 'Online Exchange ID',
    description: 'Get your Online Cricket Betting Exchange ID today! Join our secure and trusted platform for the best cricket betting experience: fast sign-up, and 24/7 support.',
    language: 'en-us',
    theme: 'system', // system, dark or light
    siteUrl: 'https://onlineexchangeid.com', // your website URL
    siteLogo: '/logo.png',
    socialBanner: '/social-banner.png', // add social banner in the public folder
    email: 'onlineexchangeid@gmail.com', 
    // github: 'https://github.com/codebucks27',
    // twitter: 'https://twitter.com/code_bucks',
    // facebook: 'https://facebook.com',
    // youtube: 'https://youtube.com/codebucks',
    // linkedin: 'https://www.linkedin.com/in/codebucks/',
    // dribbble: 'https://www.dribbble.com',
    alternates: {
      canonical: "/",
    },
    locale: 'en-US',
  }
  
  module.exports = siteMetadata